import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/dev/workspaces/js/_courses/gatsby-intro/src/components/layout.js";
import Wave from '../components/wave';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Contact Me`}</h1>
    <p>{`You can get in touch with me by opening the nearest window and yelling out of it really loud`}</p>
    <h2>{`Or you can wave to me`}</h2>
    <p>{`Just click this button to simulate a friendly wave`}</p>
    <Wave mdxType="Wave" />
    <p>{`Please not that I may not wave back.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      